<template>
  <div class="myapp d-flex flex-column justify-content-center">
    <Header active_tab="adduser"></Header>
    <hr />
    <h3 class="mx-auto my-3">Registered Users</h3>
    <input
      v-model="search"
      placeholder="Search"
      class="form-control-sm my-5 mx-5"
    />
    <ul class="list-group">
      <li
        class="list-group-item d-flex flex-column"
        v-for="(item, index) in filterEmail"
        :key="index"
      >
        <div class="inline-block">
          <div class="float-left">{{ item["email"] }}</div>

          <div class="float-right delete" @click="deleteUser(index)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              />
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </div>
          <div
            class="float-right mx-5"
            data-toggle="collapse"
            :data-target="'#collapseExample' + index"
            aria-expanded="false"
          >
            <button>More</button>
          </div>
        </div>
        <div class="collapse" :id="'collapseExample' + index">
          <div class="my-2">
            <b>Name: </b><input type="text" v-model="item['name']" />
          </div>
          <div class="my-2">
            <b>Password: </b><input type="text" v-model="item['password']" />
          </div>
          <!-- <div class="float-left">{{item['password']}}</div> -->
          <div class="card card-body my-3">
            <div class="d-flex justify-content-between">
              <div class="flaot-left">Assessment1</div>
              <label class="switch float-right">
                <input type="checkbox" v-model="item.assessment1" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="d-flex justify-content-between">
              <div class="flaot-left">Assessment2</div>
              <label class="switch float-right">
                <input type="checkbox" v-model="item.assessment2" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="d-flex justify-content-between">
              <div class="flaot-left">Assessment3</div>
              <label class="switch float-right">
                <input type="checkbox" v-model="item.assessment3" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="d-flex justify-content-between">
              <div class="flaot-left">Mentorship</div>
              <label class="switch float-right">
                <input type="checkbox" v-model="item.mentorship" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="d-flex justify-content-between">
              <div class="flaot-left">Revision</div>
              <label class="switch float-right">
                <input type="checkbox" v-model="item.revision" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="btn" @click="savePermissions(index)">Save</div>
          </div>
        </div>
      </li>
    </ul>
    <button
      class="btn btn-dark mx-auto my-4"
      v-if="!clicked"
      @click="clicked = true"
    >
      Add User
    </button>
    <div v-else>
      <div class="form-control">
        <div class="form-row">
          <div class="form-group col-md-6">
            <input
              class="form-control"
              type="text"
              v-model="name"
              placeholder="name "
            />
          </div>
          <div class="form-group col-md-6">
            <input
              class="form-control"
              type="text"
              v-model="pass"
              placeholder="password"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <input
              class="form-control"
              type="email"
              v-model="email"
              placeholder="email"
            />
          </div>
        </div>
      </div>

      <button class="btn btn-dark mx-auto my-4" @click="addThisUser">
        Add Him
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store";
import Header from "@/components/Header.vue";
import db from "@/firebase";
import firebase from "firebase";
export default {
  components: {
    Header,
  },
  //Show all users here and add a button to add user
  async mounted() {
    store.state.loading = true;
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("https://Pysberduck.pythonanywhere.com/api/allusers", {}, config)
      .then(
        async (res) => {
          if (res.data["status"] == 200) {
            this.userlist = res.data["data"];
            let data = (await db.collection("names").doc("names").get()).data();

            this.namesObject = data;
            console.log("data done");
            for (let i = 0; i < this.userlist.length; i++) {
              this.userlist[i]["name"] =
                this.namesObject[this.userlist[i]["email"]];
            }
          } else swal("Some error occured");
        },
        (err) => {
          swal("Some error occured");
        }
      )
      .then((res) => {
        store.state.loading = false;
      });
  },
  data() {
    return {
      search: "",
      userlist: [],
      clicked: false,
      email: "",
      pass: "",
      name: "",
      namesObject: {},
    };
  },
  computed: {
    filterEmail: function () {
      if (this.userlist.length == 0) return true;
      else {
        return this.userlist.filter((elem) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                elem.email.toLowerCase().includes(v) ||
                this.namesObject[elem.email]?.toLowerCase().includes(v)
            );
        });
      }
    },
  },
  methods: {
    addThisUser() {
      //Add email and pass this user
      var config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      axios
        .post(
          "https://Pysberduck.pythonanywhere.com/api/adduser",
          {
            uid: store.state.user.data.uid,

            email: this.email,
            password: this.pass,
          },
          config
        )
        .then(
          (res) => {
            res;
            if (res.data.status == "200") {
              //delete from list too

              res.data["data"];
              this.userlist.push({
                email: this.email,
                user_id: res.data["data"],
                password: this.pass,
                assessment1: false,
                assessment2: false,
                assessment3: false,
                mentorship: false,
                revision: false,
              });

              db.collection("names")
                .doc("names")
                .set(
                  {
                    [this.email]: this.name,
                  },
                  { merge: true }
                )
                .then((result) => {
                  this.namesObject[this.email] = this.name;
                  this.name = "";
                });

              this.email = "";
              this.pass = "";
            } else swal("Some error occured " + res.data);
          },
          (err) => {
            swal("Some error occured");
          }
        );
    },
    savePermissions(index) {
      // index signifies the permission of which user
      var config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      axios
        .post(
          "https://Pysberduck.pythonanywhere.com/api/save_permissions",
          this.userlist[index],
          config
        )
        .then(
          (res) => {
            if (res.data == "200") {
              $("#collapseExample" + index).collapse("hide");
            } else swal("Some error occured " + res.data);
          },
          (err) => {
            swal("Some error occured");
          }
        );
    },
    deleteUser(index) {
      var config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      //delete from list too

      axios
        .post(
          "https://Pysberduck.pythonanywhere.com/api/deleteuser",
          {
            uid: this.userlist[index].user_id,
          },
          config
        )
        .then(
          (res) => {
            if (res.data == "200") {
              db.collection("names")
                .doc("names")
                .set(
                  {
                    [this.userlist[index].email]:
                      firebase.firestore.FieldValue.delete(),
                  },
                  { merge: true }
                )
                .then((result) => {
                  this.userlist.splice(index, 1);
                });
            } else swal("Some error occured " + res.data);
          },
          (err) => {
            swal("Some error occured");
          }
        );
    },
  },
};
</script>
<style scoped>
.delete:hover {
  color: red;
  cursor: pointer;
}

.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 150px;
  background-color: #d42525;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  margin: auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
